var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-12"},[_c('accountMenu')],1),_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-sm-12"},[_vm._m(0),_c('p',{staticClass:"border-bottom mt-2"}),_c('b-tabs',{attrs:{"pills":"","justified":"","content-class":"mt-4 pt-2","nav-wrapper-class":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"text-center pt-1 pb-1"},[_c('h4',{staticClass:"title font-weight-normal mb-0"},[_vm._v(" ข้อมูลการซื้อคอร์ส ")])])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12 mt-2"},[_c('apexchart',{attrs:{"type":"area","height":"370","options":_vm.overallChartOptions,"series":_vm.itemSeries}})],1)])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"text-center pt-1 pb-1"},[_c('h4',{staticClass:"title font-weight-normal mb-0"},[_vm._v("ข้อมูลราคาขาย")])])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12 mt-2"},[_c('apexchart',{attrs:{"type":"area","height":"370","options":_vm.overallChartOptions,"series":_vm.amountSeries}})],1)])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive bg-white"},[_c('v-data-table',{staticClass:"table table-center table-padding mb-0",attrs:{"headers":_vm.headers,"items":_vm.salesHistory,"items-per-page":10},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.courses",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.courses[0].title)+" ")]}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.student.displayName)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numFormat")(item.amount,"0,0.00"))+" ")]}}])})],1)])])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h3',{staticClass:"title"},[_vm._v("Sales")])])}]

export { render, staticRenderFns }