
/**
 * Index-classic-saas component
 */
import { CheckCircleIcon, XCircleIcon } from 'vue-feather-icons'
import apexchart from "vue-apexcharts";

import omiseService from "@/services/omiseService";
import accountMenu from "@/views/account/menu.vue";
import WithdrawForm from "@/components/withdraw-form";
import withdrawService from "@/services/withdrawService";
export default {
    components: {
        CheckCircleIcon,
        XCircleIcon,
        accountMenu,
        apexchart,
        WithdrawForm,
    },
    data: () => ({
        modalWithdrawShow: false,
        amountSeries: [
            {
                name: "เงินรวมที่ขายได้",
                data: [],
            },
        ],
        itemSeries: [
            {
                name: "คอร์สที่ขายได้",
                data: [],
            },
        ],
        overallChartOptions: {
            chart: {
                toolbar: {
                    show: false,
                },
                type: "area",
                stacked: false,
                height: 350,
                zoom: {
                    type: "x",
                    enabled: false,
                    autoScaleYaxis: true,
                },
                // toolbar: {
                //     autoSelected: "zoom",
                // },
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
            },
            title: {
                text: "",
                align: "left",
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0.5,
                    stops: [0, 90, 100],
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val;
                    },
                },
                title: {
                    // text: "Num of Students",
                },
            },
            xaxis: {
                type: "datetime",
            },
            tooltip: {
                x: {
                    format: "dd/MM/yy",
                },
            },
        },
        headers: [
            {
                text: "Invoice No.",
                align: "center",
                sortable: false,
                value: "invoiceNo",
            },
            {
                text: "Course",
                align: "center",
                sortable: false,
                value: "courses",
            },
            {
                text: "Student",
                align: "center",
                sortable: false,
                value: "student",
            },
            {
                text: "Amount (THB)",
                align: "right",
                sortable: false,
                value: "amount",
            },
            {
                text: "Date",
                align: "center",
                sortable: false,
                value: "created_at",
            }
        ],
        salesHistory: [],
        balance: 0,
    }),
    mounted() {
        this.getBillingHistory();
    },
    methods: {
        getBillingHistory() {
            omiseService
                .listSalesHistory()
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        this.salesHistory = resp.data.result;
                        delete resp.data.result;

                        var today = new Date((new Date()).setHours(0, 0, 0, 0))
                        this.calculateTotalBalance();
                        this.plotSumAmountGraph(today);
                        this.plotSumItemGraph(today);
                    } else {
                        throw "Can not get customer's cards";
                    }
                })
                .catch((err) => {
                    this.salesHistory = [];
                });
        },
        calculateTotalBalance() {
            let sum = 0;
            this.salesHistory.map(x => {
                sum += x.amount;
            });

            this.balance = sum;
        },
        plotSumAmountGraph(today) {
            let series = [];
            for (let index = 0; index < 31; index++) {
                var startTime = ((new Date(today).setDate(today.getDate() - index)));
                var endTime = ((new Date(today).setDate(today.getDate() - (index) + 1)));

                let sum = 0;
                this.salesHistory.map(x => {
                    if (x.created_at > startTime && x.created_at < endTime) {
                        sum += x.amount;
                    }
                });
                series.push([
                    startTime,
                    sum
                ]);
            }

            this.amountSeries = [
                {
                    data: series,
                },
            ];
        },
        plotSumAmountGraph2(today) {
            let series = [];
            for (let index = 0; index < 31; index++) {
                var startTime = ((new Date(today).setDate(today.getDate() - index)));
                var endTime = ((new Date(today).setDate(today.getDate() - (index) + 1)));

                let sum = 0;
                this.salesHistory.map(x => {
                    if (x.created_at > startTime && x.created_at < endTime) {
                        sum += x.amount;
                    }
                });
                if (sum != 0) {
                    series.push([
                        startTime,
                        sum
                    ]);
                }

            }

            this.amountSeries = [
                {
                    data: series,
                },
            ];
        },
        plotSumItemGraph(today) {
            let series = [];
            for (let index = 0; index < 31; index++) {
                var startTime = ((new Date(today).setDate(today.getDate() - index)));
                var endTime = ((new Date(today).setDate(today.getDate() - (index) + 1)));

                let sum = 0;
                this.salesHistory.map(x => {
                    if (x.created_at > startTime && x.created_at < endTime) {
                        sum += 1;
                    }
                });
                series.push([
                    startTime,
                    sum
                ]);
            }
            this.itemSeries = [
                {
                    data: series,
                },
            ];
        },
        plotSumItemGraph2(today) {
            let series = [];
            for (let index = 0; index < 31; index++) {
                var startTime = ((new Date(today).setDate(today.getDate() - index)));
                var endTime = ((new Date(today).setDate(today.getDate() - (index) + 1)));

                let sum = 0;
                this.salesHistory.map(x => {
                    if (x.created_at > startTime && x.created_at < endTime) {
                        sum += 1;
                    }
                });
                if (sum != 0) {
                    series.push([
                        startTime,
                        sum
                    ]);
                }
            }

            this.itemSeries = [
                {
                    data: series,
                },
            ];
        },

        handleWithdrawFormOk(bvModalEvt) {
            bvModalEvt.preventDefault();

            let withdraw = this.$refs.withdrawForm.getWithdrawInfo();
            this.createWithdrawRequest(withdraw);
        },
        createWithdrawRequest(withdraw) {
            let accountNoWithoutFormat = withdraw.accountNo.replaceAll(" - ", "");
            let data = {
                type: 'bank', // 'promptpay
                amount: withdraw.amount,
                detail: {
                    account: withdraw.accountName,
                    accountNo: accountNoWithoutFormat,
                    bank: withdraw.bankName,
                }
            }

            // type: 'promptpay',
            // detail: {
            //     type: 'tel', // 'idCard'   
            //     promptpayNo: withdraw.promptpayNo,
            // }

            withdrawService
                .saveWithdraw(data)
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        this.modalWithdrawShow = false;
                        this.getBillingHistory();
                    } else {
                        throw "Can not save withdraw request";
                    }
                })
                .catch((err) => {
                    this.$bvModal.msgBoxOk(response.message, {
                        title: "Withdraw Request Error",
                        size: "sm",
                        okVariant: "danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
    },
};

